<template>
  <div
    class="w-full bg-gray-200 h-1 relative overflow-hidden mx-0"
    :class="[{ 'rounded-full': rounded }, { indeterminate: indeterminate }]"
  >
    <div
      class="h-full progressbar"
      :class="loadingStyle"
      role="progressbar"
      :style="{ width: `${percentage}%` }"
      :aria-valuenow="percentage"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <span class="flex items-center h-full">
        <slot></slot>
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { useAccentureStyle } from '@/composable/useAccentureStyle'
  import { computed } from 'vue'

  const props = withDefaults(
    defineProps<{
      backgroundColor?: string
      percentage?: number
      rounded?: boolean
      indeterminate: boolean
    }>(),
    {
      backgroundColor: 'primary',
      percentage: 0,
      rounded: false,
    }
  )
  const style = useAccentureStyle()
  const loadingStyle = computed<string[]>(() => [
    style.backgroundColors[props.backgroundColor],
    props.indeterminate ? 'absolute top-0' : '',
    props.rounded ? 'rounded-full' : '',
  ])
</script>
<style scoped>
  @keyframes progress-indeterminate {
    0% {
      width: 30%;
      left: 0;
    }
    60% {
      left: 100%;
      width: 100%;
    }
    to {
      left: 100%;
      width: 0;
    }
  }
  .progressbar {
    transition: width 0.5s ease;
  }
  .indeterminate .progressbar {
    animation: progress-indeterminate 2s linear infinite;
  }
</style>
