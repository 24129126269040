import { RouteRecordRaw } from 'vue-router'

const errorRoutes: RouteRecordRaw = {
  path: '/:pathMatch(.*)*',
  name: '404',
  component: () => import('@/view/pages/error/Error404View.vue'),
  meta: {
    title: 'My Accenture | 404',
    requiresAuth: false,
  },
}

export { errorRoutes }
