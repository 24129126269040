import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const securityIncidentsRoutes: RouteRecordRaw = {
  name: 'Reports Security Incidents',
  path: 'security-incidents',
  redirect: { name: 'Open Security Incidents' },
  component: () => import('./SecurityIncidentsLayout.vue'),
  children: [
    {
      name: 'Closed Security Incidents',
      path: 'closed',
      component: () => import('./ClosedSecurityIncidents.vue'),
      meta: {
        title: 'My Accenture | Closed Security Incidents',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Created Security Incidents',
      path: 'created',
      component: () => import('./CreatedSecurityIncidents.vue'),
      meta: {
        title: 'My Accenture | Created Security Incidents',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Open Security Incidents',
      path: 'open',
      component: () => import('./OpenSecurityIncidents.vue'),
      meta: {
        title: 'My Accenture | Open Security Incidents',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
  ],
}

export { securityIncidentsRoutes }
