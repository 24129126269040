<template>
  <div class="bg-white rounded flex flex-col flex-grow justify-between h-full">
    <div class="flex flex-col h-full">
      <div
        v-show="hasToolbarSlot"
        class="flex justify-between"
        :class="[titleBorderClasses, titlePadding]"
      >
        <div class="font-bold text-xl text-sblack-500">
          <slot name="title" />
        </div>
        <div>
          <slot name="toolbar"></slot>
        </div>
      </div>
      <div class="flex-grow justify-self-start" :class="[bodyPadding]">
        <slot name="body"></slot>
      </div>
    </div>
    <div
      v-if="hasFooterSlot"
      class="flex px-6 py-3"
      :class="[headerBorderClasses]"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, useSlots } from 'vue'

  const slots = useSlots()
  const props = withDefaults(
    defineProps<{
      titleBorder?: boolean
      footerBorder?: boolean
      titlePadding?: string
      bodyPadding?: string
      showLoading?: boolean
    }>(),
    {
      titleBorder: true,
      footerBorder: false,
      titlePadding: 'py-5 px-6',
      bodyPadding: 'px-6',
      showLoading: false,
    }
  )

  const titleBorderClasses = computed(() => {
    let classes: Array<string> = []
    if (props.titleBorder) {
      classes = ['border-b border-gray-200']
    }

    return classes
  })

  const headerBorderClasses = computed(() => {
    let classes: Array<string> = []
    if (props.footerBorder) {
      classes = ['border-t border-gray-200']
    }

    return classes
  })

  const hasFooterSlot = computed(() => {
    return !!slots['footer']
  })

  const hasToolbarSlot = computed<boolean>(
    () => !!slots['title'] || !!slots['toolbar']
  )
</script>
