<template>
  <button :class="[btnClasses]" v-bind="$attrs">
    <slot />
  </button>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'
  import { useAccentureStyle } from '@/composable/useAccentureStyle'
  import { AccentureColors, AccentureShapes, AccentureSizes } from '@/types/accentureStyle'

  const props = withDefaults(
    defineProps<{
      color?: AccentureColors
      size?: AccentureSizes
      disabled?: boolean
      round?: boolean
      outline?: boolean
      shape?: AccentureShapes
      text?: boolean
    }>(),
    {
      color: 'primary',
      shape: 'rounded',
      size: 'sm',
    }
  )

  const style = useAccentureStyle()

  const btnClasses = computed(() => {
    let classes: Array<string> = [
      'items-center justify-center cursor-pointer font-medium tracking-wider whitespace-nowrap focus:outline-none transition duration-0 hover:duration-200',
      style.shapes[props.shape],
      style.fontSizes[props.size],
    ]

    if (props.disabled) {
      classes = [
        ...classes,
        'text-gray-400 pointer-events-none',
        !props.text ? 'bg-gray-200' : '',
      ]
    } else if (props.outline) {
      classes = [
        ...classes,
        'border bg-transparent shadow-solid',
        style.borderColors[props.color],
        style.colors[props.color],
        style.outlineBackgroundColorsHover[props.color],
        style.outlineBackgroundColorsActive[props.color],
      ]
    } else if (props.text) {
      classes = [
        ...classes,
        style.colors[props.color],
        style.outlineBackgroundColorsHover[props.color],
        style.outlineBackgroundColorsActive[props.color],
      ]
    } else {
      classes = [
        ...classes,
        'border text-gray-100 shadow-solid',
        style.borderColors[props.color],
        style.backgroundColors[props.color],
        style.backgroundColorsHover[props.color],
        style.backgroundColorsActive[props.color],
      ]
    }

    if (props.round) {
      classes = [
        ...classes,
        'rounded-full flex inline-flex justify-center items-center',
        style.sizes[props.size],
      ]
    } else {
      classes = [...classes, style.padding[props.size]]
    }

    return classes
  })
</script>
