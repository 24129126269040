import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const contactsRoutes: RouteRecordRaw = {
  name: 'Contact Management',
  path: 'company-information/contacts',
  component: () => import('./ContactsView.vue'),
  meta: {
    title: 'My Accenture | Contact Management',
    requiresAuth: true,
    roles: AUTH_MODULE.NOT_PERMITTED,
  },
}

export { contactsRoutes }
