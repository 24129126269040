import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const incidentsRoutes: RouteRecordRaw = {
  name: 'Reports Incidents',
  path: 'incidents',
  redirect: { name: 'Open Incidents' },
  component: () => import('./IncidentsLayout.vue'),
  children: [
    {
      name: 'Closed Incidents',
      path: 'closed',
      component: () => import('./ClosedIncidents.vue'),
      meta: {
        title: 'My Accenture | Closed Incidents',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Created Incidents',
      path: 'created',
      component: () => import('./CreatedIncidents.vue'),
      meta: {
        title: 'My Accenture | Created Incidents',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Open Incidents',
      path: 'open',
      component: () => import('./OpenIncidents.vue'),
      meta: {
        title: 'My Accenture | Open Incidents',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
  ],
}

export { incidentsRoutes }
