<template>
  <button
    class="inline-flex justify-center items-center group hover:bg-sgreen-50 hover:rounded-md px-2 duration-300"
    aria-haspopup="true"
    :aria-expanded="open"
    @click="open = true"
  >
    <div class="flex items-center truncate">
      <div
        class="truncate ml-2 text-sm font-medium group-hover:text-gray-800 text-right"
      >
        {{ userName }}
        <div class="font-bold">
          Account: {{ account.getCurrentAccountName() }}
        </div>
      </div>
      <div
        class="bg-gray-200 p-2 rounded-md bg-sgreen-50 text-sgreen-500 font-bold ml-2"
      >
        {{ abbreviate(email) }}
      </div>
    </div>
  </button>
  <transition
    enter-active-class="transition ease-out duration-200 transform"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div v-show="open" class="fixed inset-0 overflow-hidden ml-0">
      <div class="absolute inset-0 overflow-hidden">
        <div>
          <div
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          ></div>
          <transition
            enter-active-class="transition ease-out duration-400 transform"
            enter-from-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transition ease-in duration-400 transform"
            leave-from-class="translate-x-0"
            leave-to-class="translate-x-full"
          >
            <div v-show="open" class="fixed inset-y-0 right-0 max-w-full flex">
              <div
                id="user_menu"
                ref="refUserMenu"
                class="relative w-screen max-w-sm"
              >
                <div class="h-full flex flex-col py-6 px-5 bg-white shadow-xl">
                  <div class="mb-2">
                    <div class="flex items-center">
                      <div
                        class="bg-gray-200 p-3 rounded-md bg-sgreen-50 text-sgreen-500 font-bold text-lg"
                      >
                        {{ abbreviate(email) }}
                      </div>
                      <div class="ml-4">
                        <div class="text-md font-medium text-gray-900">
                          {{ userName }}
                        </div>
                        <div class="text-md text-gray-500">
                          {{ email }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 class="text-lg mt-6">Switch Account</h1>
                  <SProgressBar
                    v-if="!userAccounts"
                    :percentage="100"
                    indeterminate
                  />
                  <div class="flex-auto overflow-y-auto">
                    <ul>
                      <template
                        v-for="(item, index) in userAccounts"
                        :key="index"
                      >
                        <li
                          class="border-l-4 px-2 py-1.5 my-2 hover:cursor-pointer hover:bg-sgray-500 duration-200"
                          :class="[
                            currentAccount === item.accountNumber
                              ? 'bg-sgray-500 font-bold text-sgold-500 border-sgold-500'
                              : 'bg-transparant text-sgreen-500 border-sgreen-500',
                          ]"
                          @click="setActiveAccount(item.accountNumber)"
                        >
                          <div>{{ item.accountName }}</div>
                          <div class="text-xs">{{ item.accountNumber }}</div>
                        </li>
                      </template>
                    </ul>
                  </div>
                  <div class="flex pt-5">
                    <router-link
                      v-slot="{ href, navigate }"
                      to="/terms-of-use"
                      custom
                    >
                      <a
                        class="block transition duration-150 truncate text-sgold-500 hover:text-sgold-500"
                        :href="href"
                        @click="navigate"
                      >
                        <span
                          class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 justify-center"
                        >
                          Terms of Use
                        </span>
                      </a>
                    </router-link>
                  </div>
                  <div class="flex pt-5">
                    <SButton @click="signOut">Sign out</SButton>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useAuthentication } from '@/store/useAuthentication'
  import SButton from '@/components/SButton/SButton.vue'
  import SProgressBar from '@/components/SProgressBar/SProgressBar.vue'
  import { useAccounts } from '@/store/useAccount'
  import { storeToRefs } from 'pinia'
  import { onClickOutside } from '@vueuse/core'
  import { abbreviate } from '@/utils/stringFormat'
  import { useRouter } from 'vue-router'

  // Stores
  const authentication = useAuthentication()
  const account = useAccounts()

  // Refs
  const open = ref(false)
  const { currentAccount, userAccounts } = storeToRefs(account)
  const refUserMenu = ref()

  // Click outside
  onClickOutside(refUserMenu, () => (open.value = false))

  const email = computed<string>(
    () => authentication.idTokenPayload?.email as string
  )
  const userName = computed(() => {
    let segments = email.value.split('@')
    let naming = segments[0].split('.')
    let userNames: Array<string> = []
    naming.forEach((namePart: string) => {
      namePart = namePart[0].toUpperCase() + namePart.slice(1)
      userNames.push(namePart)
    })
    return userNames.join(' ')
  })

  // Functions
  const router = useRouter()
  const signOut = () => {
    router.push({ path: '/auth/logout' })
  }

  const setActiveAccount = (accountNumber: string) => {
    account.setCurrentAccount(accountNumber)
    window.location.replace('/home')
  }
</script>
