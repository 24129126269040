import { graphql } from '@/gql/gql'

const LIST_ACCOUNT_WITH_OWNER_AND_MANAGER = graphql(`
  query listAccountWithOwnerAndManager {
    customers {
      accounts {
        totalCount
        result {
          accountId
          accountNumber
          accountName
          addresses {
            result {
              city
              country
              postalCode
              lines
            }
            metadata {
              conversationId
              statusCode
              statusText
              message
            }
          }
          emailAddresses
          vatNumber
          organizationalUnit
          owner {
            result {
              emailAddress
              firstName
              lastName
              mobilePhone
            }
          }
          serviceManager {
            result {
              emailAddress
              firstName
              lastName
              mobilePhone
            }
          }
          telephone
        }
        metadata {
          conversationId
          statusCode
          statusText
        }
      }
    }
  }
`)

const LIST_ACCOUNT_WITH_SERVICENOW_ACCOUNT = graphql(`
  query listAccountWithServiceNowAccount(
    $filters: Dynamics365CEAccountFilter!
  ) {
    customers {
      accounts(filters: $filters) {
        result {
          accountNumber
          serviceNowAccount {
            result {
              sysId
              supportInformation
            }
          }
        }
        totalCount
        metadata {
          conversationId
          statusCode
          statusText
        }
      }
    }
  }
`)

const LIST_USER_ACCOUNTS = graphql(`
  query listUserAccount {
    user {
      accounts {
        result {
          accountNumber
          accountName
        }
        metadata {
          conversationId
          statusCode
          statusText
        }
      }
    }
  }
`)

export {
  LIST_ACCOUNT_WITH_OWNER_AND_MANAGER,
  LIST_USER_ACCOUNTS,
  LIST_ACCOUNT_WITH_SERVICENOW_ACCOUNT,
}
