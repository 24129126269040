import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const casesRoutes: RouteRecordRaw = {
  name: 'Reports Cases',
  path: 'cases',
  redirect: { name: 'Open Cases' },
  component: () => import('./CasesLayout.vue'),
  children: [
    {
      name: 'Closed Cases',
      path: 'closed',
      component: () => import('./ClosedCases.vue'),
      meta: {
        title: 'My Accenture | Closed Cases',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Created Cases',
      path: 'created',
      component: () => import('./CreatedCases.vue'),
      meta: {
        title: 'My Accenture | Created Cases',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Open Cases',
      path: 'open',
      component: () => import('./OpenCases.vue'),
      meta: {
        title: 'My Accenture | Open Cases',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
  ],
}

export { casesRoutes }
