/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSDate: any
  AWSDateTime: any
  AWSEmail: any
  AWSIPAddress: any
  AWSJSON: any
  AWSPhone: any
  AWSTime: any
  AWSTimestamp: any
  AWSURL: any
}

export type CustomersResult = {
  __typename?: 'CustomersResult'
  accounts?: Maybe<Dynamics365CeAccountsResult>
  contacts?: Maybe<Dynamics365CeContactsResult>
}

export type CustomersResultAccountsArgs = {
  filters?: InputMaybe<Dynamics365CeAccountFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  sorting?: InputMaybe<Array<InputMaybe<Dynamics365CeAccountSorting>>>
}

export type CustomersResultContactsArgs = {
  filters?: InputMaybe<Dynamics365CeContactFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  sorting?: InputMaybe<Array<InputMaybe<Dynamics365CeContactSorting>>>
}

export type Dynamics365CeAccount = {
  __typename?: 'Dynamics365CEAccount'
  accountId?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  accountNumber?: Maybe<Scalars['String']>
  addresses?: Maybe<Dynamics365CeCustomerAddressesResult>
  emailAddresses?: Maybe<Array<Maybe<Scalars['AWSEmail']>>>
  organizationalUnit?: Maybe<Scalars['String']>
  owner?: Maybe<Dynamics365CeSystemUserResult>
  serviceManager?: Maybe<Dynamics365CeSystemUserResult>
  serviceNowAccount?: Maybe<ServiceNowAccountResult>
  telephone?: Maybe<Scalars['String']>
  vatNumber?: Maybe<Scalars['String']>
}

export type Dynamics365CeAccountFilter = {
  emailAddress?: InputMaybe<GenericStringFilter>
}

export type Dynamics365CeAccountSorting = {
  accountNumber?: InputMaybe<GenericSortOrderEnum>
  createdOn?: InputMaybe<GenericSortOrderEnum>
  emailAddress?: InputMaybe<GenericSortOrderEnum>
  name?: InputMaybe<GenericSortOrderEnum>
}

export type Dynamics365CeAccountsResult = {
  __typename?: 'Dynamics365CEAccountsResult'
  metadata: Metadata
  result?: Maybe<Array<Maybe<Dynamics365CeAccount>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type Dynamics365CeContact = {
  __typename?: 'Dynamics365CEContact'
  accountId?: Maybe<Scalars['String']>
  contactId?: Maybe<Scalars['String']>
  contactType?: Maybe<Scalars['String']>
  emailAddress?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  middleName?: Maybe<Scalars['String']>
  mobilePhone?: Maybe<Scalars['String']>
  supportPortalAccess?: Maybe<Scalars['Boolean']>
}

export type Dynamics365CeContactFilter = {
  emailAddress?: InputMaybe<GenericStringFilter>
}

export type Dynamics365CeContactSorting = {
  emailAddress?: InputMaybe<GenericSortOrderEnum>
}

export type Dynamics365CeContactsResult = {
  __typename?: 'Dynamics365CEContactsResult'
  metadata: Metadata
  result?: Maybe<Array<Maybe<Dynamics365CeContact>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type Dynamics365CeCustomerAddress = {
  __typename?: 'Dynamics365CECustomerAddress'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  createdOn?: Maybe<Scalars['AWSDateTime']>
  customerAddressId?: Maybe<Scalars['ID']>
  lines?: Maybe<Array<Maybe<Scalars['String']>>>
  modifiedOn?: Maybe<Scalars['AWSDateTime']>
  postalCode?: Maybe<Scalars['String']>
  telephoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>
  type?: Maybe<Dynamics365CeCustomerAddressTypeCodeEnum>
}

export enum Dynamics365CeCustomerAddressTypeCodeEnum {
  BillTo = 'BILL_TO',
  Other = 'OTHER',
  Primary = 'PRIMARY',
  ShipTo = 'SHIP_TO',
}

export type Dynamics365CeCustomerAddressesResult = {
  __typename?: 'Dynamics365CECustomerAddressesResult'
  metadata?: Maybe<Metadata>
  result?: Maybe<Array<Maybe<Dynamics365CeCustomerAddress>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type Dynamics365CeSystemUser = {
  __typename?: 'Dynamics365CESystemUser'
  emailAddress?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  mobilePhone?: Maybe<Scalars['String']>
}

export type Dynamics365CeSystemUserResult = {
  __typename?: 'Dynamics365CESystemUserResult'
  metadata: Metadata
  result?: Maybe<Dynamics365CeSystemUser>
}

export type GenericBooleanFilterExact = {
  operator?: InputMaybe<GenericEqualsOperatorEnum>
  value?: InputMaybe<Array<Scalars['Boolean']>>
}

export enum GenericCloudProviderEnum {
  Aws = 'AWS',
  Azure = 'AZURE',
}

export type GenericCloudProviderFilter = {
  operator?: InputMaybe<GenericEnumOperatorEnum>
  value: Array<GenericCloudProviderEnum>
}

export type GenericCloudProviderFilterExact = {
  operator?: InputMaybe<GenericEqualsOperatorEnum>
  value: Array<GenericCloudProviderEnum>
}

export enum GenericCountryEnum {
  Be = 'BE',
  Bg = 'BG',
  Dk = 'DK',
  Nl = 'NL',
}

export type GenericCountryFilter = {
  operator?: InputMaybe<GenericEnumOperatorEnum>
  value: Array<GenericCountryEnum>
}

export type GenericCountryFilterExact = {
  operator?: InputMaybe<GenericEqualsOperatorEnum>
  value: Array<GenericCountryEnum>
}

export type GenericDateFilter = {
  operator?: InputMaybe<GenericDateOperatorEnum>
  value: Array<Scalars['AWSDate']>
}

export enum GenericDateOperatorEnum {
  Between = 'BETWEEN',
  Equals = 'EQUALS',
  GreaterThanEquals = 'GREATER_THAN_EQUALS',
  In = 'IN',
  LessThanEquals = 'LESS_THAN_EQUALS',
  NotEquals = 'NOT_EQUALS',
  NotIn = 'NOT_IN',
}

export type GenericDateTimeFilter = {
  operator?: InputMaybe<GenericDateTimeOperatorEnum>
  value: Array<Scalars['AWSDateTime']>
}

export enum GenericDateTimeOperatorEnum {
  Between = 'BETWEEN',
  Equals = 'EQUALS',
  GreaterThanEquals = 'GREATER_THAN_EQUALS',
  LessThanEquals = 'LESS_THAN_EQUALS',
}

export enum GenericEnumOperatorEnum {
  Equals = 'EQUALS',
  In = 'IN',
  NotEquals = 'NOT_EQUALS',
  NotIn = 'NOT_IN',
}

export enum GenericEqualsOperatorEnum {
  Equals = 'EQUALS',
}

export type GenericFloatFilter = {
  operator?: InputMaybe<GenericNumericOperatorEnum>
  value: Array<Scalars['Float']>
}

export type GenericFloatFilterExact = {
  operator?: InputMaybe<GenericEqualsOperatorEnum>
  value: Array<Scalars['Float']>
}

export type GenericIdFilter = {
  operator?: InputMaybe<GenericIdOperatorEnum>
  value: Array<Scalars['ID']>
}

export type GenericIdFilterExact = {
  operator?: InputMaybe<GenericEqualsOperatorEnum>
  value: Array<Scalars['ID']>
}

export enum GenericIdOperatorEnum {
  Equals = 'EQUALS',
  In = 'IN',
}

export type GenericIntFilter = {
  operator?: InputMaybe<GenericNumericOperatorEnum>
  value: Array<Scalars['Int']>
}

export type GenericIntFilterExact = {
  operator?: InputMaybe<GenericEqualsOperatorEnum>
  value: Array<Scalars['Int']>
}

export enum GenericIsoCountryCodeEnum {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Fx = 'FX',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  St = 'ST',
  Sv = 'SV',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tp = 'TP',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xi = 'XI',
  Ye = 'YE',
  Yt = 'YT',
  Yu = 'YU',
  Za = 'ZA',
  Zm = 'ZM',
  Zr = 'ZR',
  Zw = 'ZW',
}

export type GenericIsoCountryCodeFilterExact = {
  operator?: InputMaybe<GenericEqualsOperatorEnum>
  value: Array<GenericIsoCountryCodeEnum>
}

export type GenericJsonResult = {
  __typename?: 'GenericJSONResult'
  metadata: Metadata
  result?: Maybe<Scalars['AWSJSON']>
}

export type GenericMetadataResult = {
  __typename?: 'GenericMetadataResult'
  metadata: Metadata
}

export type GenericMutationAsyncResult = {
  __typename?: 'GenericMutationAsyncResult'
  metadata: Metadata
  result?: Maybe<MutationAsyncResult>
}

export enum GenericMutationAsyncStatusEnum {
  Accepted = 'ACCEPTED',
  Failed = 'FAILED',
}

export type GenericNameValuePair = {
  __typename?: 'GenericNameValuePair'
  name?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export enum GenericNumericOperatorEnum {
  Between = 'BETWEEN',
  Equals = 'EQUALS',
  GreaterThanEquals = 'GREATER_THAN_EQUALS',
  In = 'IN',
  LessThanEquals = 'LESS_THAN_EQUALS',
  NotEquals = 'NOT_EQUALS',
  NotIn = 'NOT_IN',
}

export type GenericPaginationLimitNextToken = {
  limit?: InputMaybe<Scalars['Int']>
  nextToken?: InputMaybe<Scalars['String']>
}

export type GenericPaginationLimitOffset = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export enum GenericSortOrderEnum {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type GenericStringFilter = {
  operator?: InputMaybe<GenericStringOperatorEnum>
  value: Array<Scalars['String']>
}

export type GenericStringFilterExact = {
  operator?: InputMaybe<GenericEqualsOperatorEnum>
  value: Array<Scalars['String']>
}

export enum GenericStringOperatorEnum {
  Equals = 'EQUALS',
  In = 'IN',
  Like = 'LIKE',
  NotEquals = 'NOT_EQUALS',
  NotIn = 'NOT_IN',
}

export type GenericTimeFilter = {
  operator?: InputMaybe<GenericTimeOperatorEnum>
  value: Array<Scalars['AWSTime']>
}

export enum GenericTimeOperatorEnum {
  Between = 'BETWEEN',
  Equals = 'EQUALS',
  GreaterThanEquals = 'GREATER_THAN_EQUALS',
  LessThanEquals = 'LESS_THAN_EQUALS',
}

export type InsightsResult = {
  __typename?: 'InsightsResult'
  powerBI?: Maybe<PowerBiResult>
  zabbix?: Maybe<ZabbixResult>
}

export type InsightsResultPowerBiArgs = {
  filters: PowerBiFilterInput
}

export type Metadata = {
  __typename?: 'Metadata'
  conversationId: Scalars['ID']
  message: Scalars['String']
  statusCode: Scalars['Int']
  statusText: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  reportFileGenerate: ReportFileGenerateResult
  serviceNowAccountUpdate: ServiceNowAccountResult
  serviceNowCaseCreate: ServiceNowCaseResult
  serviceNowContactCaseCreate: ServiceNowCaseResult
}

export type MutationReportFileGenerateArgs = {
  input: ReportFileGenerateMutationInput
}

export type MutationServiceNowAccountUpdateArgs = {
  input: ServiceNowAccountUpdateMutationInput
}

export type MutationServiceNowCaseCreateArgs = {
  input: ServiceNowCaseCreateMutationInput
}

export type MutationServiceNowContactCaseCreateArgs = {
  input: ServiceNowContactCaseCreateMutationInput
}

export type MutationAsyncResult = {
  __typename?: 'MutationAsyncResult'
  status?: Maybe<GenericMutationAsyncStatusEnum>
}

export type OperationsResult = {
  __typename?: 'OperationsResult'
  cases?: Maybe<ServiceNowCasesResult>
  changes?: Maybe<ServiceNowChangesResult>
  configurationItems?: Maybe<ServiceNowConfigurationItemsResult>
  incidents?: Maybe<ServiceNowIncidentsResult>
  problems?: Maybe<ServiceNowProblemsResult>
}

export type OperationsResultCasesArgs = {
  filters?: InputMaybe<ServiceNowCaseFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  sorting?: InputMaybe<Array<InputMaybe<ServiceNowCaseSorting>>>
}

export type OperationsResultChangesArgs = {
  filters?: InputMaybe<ServiceNowChangeFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  sorting?: InputMaybe<Array<InputMaybe<ServiceNowChangeSorting>>>
}

export type OperationsResultConfigurationItemsArgs = {
  filters?: InputMaybe<ServiceNowConfigurationItemFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  sorting?: InputMaybe<Array<InputMaybe<ServiceNowConfigurationItemSorting>>>
}

export type OperationsResultIncidentsArgs = {
  filters?: InputMaybe<ServiceNowIncidentFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  sorting?: InputMaybe<Array<InputMaybe<ServiceNowIncidentSorting>>>
}

export type OperationsResultProblemsArgs = {
  filters?: InputMaybe<ServiceNowProblemFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  sorting?: InputMaybe<Array<InputMaybe<ServiceNowProblemSorting>>>
}

export type PowerBiEmbedToken = {
  __typename?: 'PowerBIEmbedToken'
  embedToken?: Maybe<Scalars['String']>
  embedUrl?: Maybe<Scalars['String']>
  reportId?: Maybe<Scalars['String']>
}

export type PowerBiFilterInput = {
  reportName: PowerBiReportsInputExact
}

export enum PowerBiReportsEnum {
  Invoices = 'INVOICES',
  Licenses = 'LICENSES',
}

export type PowerBiReportsInputExact = {
  operator?: InputMaybe<GenericEqualsOperatorEnum>
  value: Array<PowerBiReportsEnum>
}

export type PowerBiResult = {
  __typename?: 'PowerBIResult'
  metadata: Metadata
  result?: Maybe<PowerBiEmbedToken>
}

export type PreSignedUrlResult = {
  __typename?: 'PreSignedUrlResult'
  preSignedUrl?: Maybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  customers?: Maybe<CustomersResult>
  insights?: Maybe<InsightsResult>
  operations?: Maybe<OperationsResult>
  user?: Maybe<UserResult>
}

export type ReportFileGenerateMutationInput = {
  accountNumber: Scalars['String']
  context: Scalars['AWSJSON']
  fileName: Scalars['String']
  template: TemplateEnum
}

export type ReportFileGenerateResult = {
  __typename?: 'ReportFileGenerateResult'
  metadata?: Maybe<Metadata>
  result?: Maybe<PreSignedUrlResult>
}

export type ServiceNowAccount = {
  __typename?: 'ServiceNowAccount'
  name?: Maybe<Scalars['String']>
  supportInformation?: Maybe<Scalars['String']>
  sysId?: Maybe<Scalars['String']>
}

export type ServiceNowAccountResult = {
  __typename?: 'ServiceNowAccountResult'
  metadata: Metadata
  result?: Maybe<ServiceNowAccount>
}

export type ServiceNowAccountUpdateMutationInput = {
  accountNumber: Scalars['String']
  supportInformation?: InputMaybe<Scalars['String']>
  sysId?: InputMaybe<Scalars['String']>
}

export type ServiceNowCase = {
  __typename?: 'ServiceNowCase'
  category?: Maybe<ServiceNowCaseCategoryEnum>
  comments?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  priority?: Maybe<ServiceNowCasePriorityEnum>
  severity?: Maybe<ServiceNowCaseSeverityEnum>
  shortDescription?: Maybe<Scalars['String']>
  state?: Maybe<ServiceNowCaseStateEnum>
  sysCreatedBy?: Maybe<Scalars['String']>
  sysCreatedOn?: Maybe<Scalars['AWSDateTime']>
  sysId?: Maybe<Scalars['String']>
  sysUpdatedBy?: Maybe<Scalars['String']>
  sysUpdatedOn?: Maybe<Scalars['AWSDateTime']>
  workNotes?: Maybe<Scalars['String']>
}

export enum ServiceNowCaseCategoryEnum {
  ChangeRequest = 'CHANGE_REQUEST',
  Incident = 'INCIDENT',
  NotSet = 'NOT_SET',
  Problem = 'PROBLEM',
  Question = 'QUESTION',
  SecurityIncident = 'SECURITY_INCIDENT',
  ServiceRequest = 'SERVICE_REQUEST',
}

export enum ServiceNowCaseContactTypeEnum {
  Alert = 'ALERT',
  Chat = 'CHAT',
  Community = 'COMMUNITY',
  Email = 'EMAIL',
  Integration = 'INTEGRATION',
  Monitoring = 'MONITORING',
  NotSet = 'NOT_SET',
  Phone = 'PHONE',
  SelfService = 'SELF_SERVICE',
  Social = 'SOCIAL',
  VirtualAgent = 'VIRTUAL_AGENT',
  Web = 'WEB',
}

export type ServiceNowCaseCreateMutationInput = {
  accountNumber: Scalars['String']
  category: ServiceNowCaseCategoryEnum
  cmdbCi: Scalars['String']
  description: Scalars['String']
  priority?: InputMaybe<ServiceNowCasePriorityEnum>
  severity?: InputMaybe<ServiceNowCaseSeverityEnum>
  shortDescription: Scalars['String']
}

export type ServiceNowCaseFilter = {
  contactId?: InputMaybe<GenericStringFilter>
  state?: InputMaybe<ServiceNowCaseStateFilter>
  sysCreatedOn?: InputMaybe<GenericDateFilter>
}

export enum ServiceNowCaseImpactEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  NotSet = 'NOT_SET',
}

export enum ServiceNowCasePriorityEnum {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE',
  NotSet = 'NOT_SET',
  Planning = 'PLANNING',
}

export enum ServiceNowCaseResolutionCodeEnum {
  CancelledByCustomer = 'CANCELLED_BY_CUSTOMER',
  CancelledBySentia = 'CANCELLED_BY_SENTIA',
  ClosedOrResolvedByCustomer = 'CLOSED_OR_RESOLVED_BY_CUSTOMER',
  DuplicateIssue = 'DUPLICATE_ISSUE',
  InconclusiveCannotReproduce = 'INCONCLUSIVE_CANNOT_REPRODUCE',
  InconclusiveNoWorkAround = 'INCONCLUSIVE_NO_WORK_AROUND',
  InconclusiveOoo = 'INCONCLUSIVE_OOO',
  InvalidRequest = 'INVALID_REQUEST',
  NotSet = 'NOT_SET',
  SolvedByCustomer = 'SOLVED_BY_CUSTOMER',
  SolvedBySupport = 'SOLVED_BY_SUPPORT',
  SolvedFixedClosingRelatedChange = 'SOLVED_FIXED_CLOSING_RELATED_CHANGE',
  SolvedFixedClosingRelatedIncident = 'SOLVED_FIXED_CLOSING_RELATED_INCIDENT',
  SolvedFixedClosingRelatedProblem = 'SOLVED_FIXED_CLOSING_RELATED_PROBLEM',
  SolvedPermanently = 'SOLVED_PERMANENTLY',
  SolvedRelatedRequestComplete = 'SOLVED_RELATED_REQUEST_COMPLETE',
  SolvedWorkAround = 'SOLVED_WORK_AROUND',
  SolvedWorkAroundProvided = 'SOLVED_WORK_AROUND_PROVIDED',
  Successful = 'SUCCESSFUL',
  SuccessfulWithIssues = 'SUCCESSFUL_WITH_ISSUES',
  Unresolved = 'UNRESOLVED',
  Unsuccessful = 'UNSUCCESSFUL',
  VoidedOrCancelled = 'VOIDED_OR_CANCELLED',
}

export type ServiceNowCaseResult = {
  __typename?: 'ServiceNowCaseResult'
  metadata: Metadata
  result?: Maybe<ServiceNowCase>
}

export enum ServiceNowCaseSeverityEnum {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE',
  NotSet = 'NOT_SET',
}

export type ServiceNowCaseSorting = {
  sysCreatedOn?: InputMaybe<GenericSortOrderEnum>
}

export enum ServiceNowCaseStateEnum {
  AwaitingInfo = 'AWAITING_INFO',
  Closed = 'CLOSED',
  New = 'NEW',
  NotSet = 'NOT_SET',
  Open = 'OPEN',
  Resolved = 'RESOLVED',
}

export type ServiceNowCaseStateFilter = {
  operator?: InputMaybe<GenericEnumOperatorEnum>
  value: Array<ServiceNowCaseStateEnum>
}

export enum ServiceNowCaseSubcategoryEnum {
  Availability = 'AVAILABILITY',
  Aws = 'AWS',
  Azure = 'AZURE',
  Backup = 'BACKUP',
  CompromisedAsset = 'COMPROMISED_ASSET',
  Connectivity = 'CONNECTIVITY',
  CoLocation = 'CO_LOCATION',
  DdosAttack = 'DDOS_ATTACK',
  Firewall = 'FIREWALL',
  NotSet = 'NOT_SET',
  Other = 'OTHER',
  Performance = 'PERFORMANCE',
  PersonalDataBreach = 'PERSONAL_DATA_BREACH',
  PossibleThreat = 'POSSIBLE_THREAT',
  QuestionForCompliance = 'QUESTION_FOR_COMPLIANCE',
  QuestionForFinance = 'QUESTION_FOR_FINANCE',
  QuestionForSales = 'QUESTION_FOR_SALES',
  QuestionOther = 'QUESTION_OTHER',
  SelfServiceCloudCompute = 'SELF_SERVICE_CLOUD_COMPUTE',
  SentiaCloudCompute = 'SENTIA_CLOUD_COMPUTE',
  StandardOrder = 'STANDARD_ORDER',
  Vulnerability_0Day = 'VULNERABILITY_0_DAY',
}

export enum ServiceNowCaseUrgencyEnum {
  Low = 'LOW',
  Medium = 'MEDIUM',
  NotSet = 'NOT_SET',
}

export type ServiceNowCasesResult = {
  __typename?: 'ServiceNowCasesResult'
  metadata: Metadata
  result?: Maybe<Array<Maybe<ServiceNowCase>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type ServiceNowChange = {
  __typename?: 'ServiceNowChange'
  category?: Maybe<ServiceNowChangeCategoryEnum>
  comments?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  impact?: Maybe<ServiceNowChangeImpactEnum>
  number?: Maybe<Scalars['String']>
  priority?: Maybe<ServiceNowChangePriorityEnum>
  shortDescription?: Maybe<Scalars['String']>
  state?: Maybe<ServiceNowChangeStateEnum>
  sysCreatedBy?: Maybe<Scalars['String']>
  sysCreatedOn?: Maybe<Scalars['AWSDateTime']>
  sysId?: Maybe<Scalars['String']>
  sysUpdatedBy?: Maybe<Scalars['String']>
  sysUpdatedOn?: Maybe<Scalars['AWSDateTime']>
  workNotes?: Maybe<Scalars['String']>
}

export enum ServiceNowChangeBillableEnum {
  No = 'NO',
  NotSet = 'NOT_SET',
  Undetermined = 'UNDETERMINED',
  Yes = 'YES',
}

export enum ServiceNowChangeCategoryEnum {
  ApplicationsSoftware = 'APPLICATIONS_SOFTWARE',
  ChangeRequest = 'CHANGE_REQUEST',
  Documentation = 'DOCUMENTATION',
  Hardware = 'HARDWARE',
  Network = 'NETWORK',
  NotSet = 'NOT_SET',
  Other = 'OTHER',
  Service = 'SERVICE',
  ServiceNowDeployment = 'SERVICE_NOW_DEPLOYMENT',
  Software = 'SOFTWARE',
  SystemSoftware = 'SYSTEM_SOFTWARE',
  Telecom = 'TELECOM',
}

export enum ServiceNowChangeCloseCodeEnum {
  CancelledByCustomer = 'CANCELLED_BY_CUSTOMER',
  CancelledBySentia = 'CANCELLED_BY_SENTIA',
  ClosedOrResolvedByCustomer = 'CLOSED_OR_RESOLVED_BY_CUSTOMER',
  NotSet = 'NOT_SET',
  Successful = 'SUCCESSFUL',
  SuccessfulWithIssues = 'SUCCESSFUL_WITH_ISSUES',
  Unsuccessful = 'UNSUCCESSFUL',
}

export type ServiceNowChangeFilter = {
  state?: InputMaybe<ServiceNowChangeStateFilter>
  sysCreatedOn?: InputMaybe<GenericDateFilter>
}

export enum ServiceNowChangeImpactEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  NotSet = 'NOT_SET',
}

export enum ServiceNowChangePriorityEnum {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE',
  NotSet = 'NOT_SET',
  Planning = 'PLANNING',
}

export enum ServiceNowChangeRiskEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  None = 'NONE',
  NotSet = 'NOT_SET',
  VeryHigh = 'VERY_HIGH',
}

export type ServiceNowChangeSorting = {
  sysCreatedOn?: InputMaybe<GenericSortOrderEnum>
}

export enum ServiceNowChangeStateEnum {
  Assess = 'ASSESS',
  Authorize = 'AUTHORIZE',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Implement = 'IMPLEMENT',
  New = 'NEW',
  NotSet = 'NOT_SET',
  Review = 'REVIEW',
  Scheduled = 'SCHEDULED',
}

export type ServiceNowChangeStateFilter = {
  operator?: InputMaybe<GenericEnumOperatorEnum>
  value: Array<ServiceNowChangeStateEnum>
}

export enum ServiceNowChangeSubcategoryEnum {
  Aws = 'AWS',
  Azure = 'AZURE',
  Backup = 'BACKUP',
  CoLocation = 'CO_LOCATION',
  Firewall = 'FIREWALL',
  NotSet = 'NOT_SET',
  SelfServiceCloudCompute = 'SELF_SERVICE_CLOUD_COMPUTE',
  SentiaCloudCompute = 'SENTIA_CLOUD_COMPUTE',
}

export enum ServiceNowChangeTypeEnum {
  Emergency = 'EMERGENCY',
  Normal = 'NORMAL',
  NotSet = 'NOT_SET',
  Standard = 'STANDARD',
}

export type ServiceNowChangesResult = {
  __typename?: 'ServiceNowChangesResult'
  metadata: Metadata
  result?: Maybe<Array<Maybe<ServiceNowChange>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type ServiceNowConfigurationItem = {
  __typename?: 'ServiceNowConfigurationItem'
  cases?: Maybe<ServiceNowCasesResult>
  cpuCount?: Maybe<Scalars['Int']>
  hostName?: Maybe<Scalars['String']>
  installStatus?: Maybe<ServiceNowConfigurationItemInstallStatusEnum>
  ipAddress?: Maybe<Scalars['String']>
  logicalTag?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  os?: Maybe<Scalars['String']>
  osVersion?: Maybe<Scalars['String']>
  patchDaySchedule?: Maybe<Scalars['String']>
  patchMonthSchedule?: Maybe<Scalars['String']>
  patchTime?: Maybe<Scalars['String']>
  patchWeekSchedule?: Maybe<Scalars['String']>
  patchingDisabledReason?: Maybe<Scalars['String']>
  patchingEnabled?: Maybe<Scalars['Boolean']>
  ram?: Maybe<Scalars['Int']>
  shortDescription?: Maybe<Scalars['String']>
  supportLevel?: Maybe<Scalars['String']>
  sysClassName?: Maybe<Scalars['String']>
  sysId?: Maybe<Scalars['String']>
  vmTechnology?: Maybe<Scalars['String']>
}

export type ServiceNowConfigurationItemCasesArgs = {
  filtering?: InputMaybe<ServiceNowCaseFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  sorting?: InputMaybe<Array<InputMaybe<ServiceNowCaseSorting>>>
}

export enum ServiceNowConfigurationItemEnvironmentEnum {
  Acceptance = 'ACCEPTANCE',
  Development = 'DEVELOPMENT',
  Production = 'PRODUCTION',
  Test = 'TEST',
}

export type ServiceNowConfigurationItemFilter = {
  description?: InputMaybe<GenericStringFilter>
  hostName?: InputMaybe<GenericStringFilter>
  installStatus?: InputMaybe<ServiceNowConfigurationItemInstallStatusFilter>
  name?: InputMaybe<GenericStringFilter>
  os?: InputMaybe<GenericStringFilter>
  osVersion?: InputMaybe<GenericStringFilter>
  referenceIp?: InputMaybe<GenericStringFilter>
  supportLevel?: InputMaybe<GenericStringFilter>
  sysClassName?: InputMaybe<GenericStringFilter>
  sysId?: InputMaybe<GenericStringFilter>
  vmTechnology?: InputMaybe<GenericStringFilter>
}

export enum ServiceNowConfigurationItemInstallStatusEnum {
  Absent = 'ABSENT',
  Installed = 'INSTALLED',
  InMaintenance = 'IN_MAINTENANCE',
  InStock = 'IN_STOCK',
  Junk = 'JUNK',
  OnOrder = 'ON_ORDER',
  PendingInstall = 'PENDING_INSTALL',
  PendingRepair = 'PENDING_REPAIR',
  PendingRetirement = 'PENDING_RETIREMENT',
  Retired = 'RETIRED',
  Stale = 'STALE',
  Stolen = 'STOLEN',
  Unmanaged = 'UNMANAGED',
}

export type ServiceNowConfigurationItemInstallStatusFilter = {
  operator?: InputMaybe<GenericEnumOperatorEnum>
  value: Array<ServiceNowConfigurationItemInstallStatusEnum>
}

export enum ServiceNowConfigurationItemOperationalStatusEnum {
  DrStandby = 'DR_STANDBY',
  NonOperational = 'NON_OPERATIONAL',
  Operational = 'OPERATIONAL',
  Ready = 'READY',
  RepairInProgress = 'REPAIR_IN_PROGRESS',
  Retired = 'RETIRED',
}

export enum ServiceNowConfigurationItemPatchingDisabledReasonEnum {
  Api = 'API',
  Customer = 'CUSTOMER',
  EndOfLife = 'END_OF_LIFE',
  Manual = 'MANUAL',
  Na = 'NA',
}

export type ServiceNowConfigurationItemSorting = {
  installStatus?: InputMaybe<GenericSortOrderEnum>
  name?: InputMaybe<GenericSortOrderEnum>
  sysClassName?: InputMaybe<GenericSortOrderEnum>
}

export enum ServiceNowConfigurationItemSupportLevelEnum {
  BusinessCritical = 'BUSINESS_CRITICAL',
  MissionCritical = 'MISSION_CRITICAL',
  NonCritical = 'NON_CRITICAL',
  Unmanaged = 'UNMANAGED',
}

export enum ServiceNowConfigurationItemTimeZoneEnum {
  CanadaAtlantic = 'CANADA_ATLANTIC',
  CanadaCentral = 'CANADA_CENTRAL',
  CanadaEastern = 'CANADA_EASTERN',
  CanadaMountain = 'CANADA_MOUNTAIN',
  CanadaPacific = 'CANADA_PACIFIC',
  EuropeAmsterdam = 'EUROPE_AMSTERDAM',
  EuropeBerlin = 'EUROPE_BERLIN',
  EuropeBrussels = 'EUROPE_BRUSSELS',
  EuropeCopenhagen = 'EUROPE_COPENHAGEN',
  EuropeDublin = 'EUROPE_DUBLIN',
  EuropeLondon = 'EUROPE_LONDON',
  EuropeMadrid = 'EUROPE_MADRID',
  EuropeParis = 'EUROPE_PARIS',
  EuropeRome = 'EUROPE_ROME',
  EuropeSofia = 'EUROPE_SOFIA',
  EuropeStockholm = 'EUROPE_STOCKHOLM',
  EuropeZurich = 'EUROPE_ZURICH',
  Gmt = 'GMT',
  Hongkong = 'HONGKONG',
  UsArizona = 'US_ARIZONA',
  UsCentral = 'US_CENTRAL',
  UsEastern = 'US_EASTERN',
  UsHawaii = 'US_HAWAII',
  UsMountain = 'US_MOUNTAIN',
  UsPacific = 'US_PACIFIC',
}

export type ServiceNowConfigurationItemsResult = {
  __typename?: 'ServiceNowConfigurationItemsResult'
  metadata: Metadata
  result?: Maybe<Array<Maybe<ServiceNowConfigurationItem>>>
  totalCount?: Maybe<Scalars['Int']>
}

export enum ServiceNowContactCaseCreateActionEnum {
  Create = 'CREATE',
  Disable = 'DISABLE',
  Edit = 'EDIT',
}

export type ServiceNowContactCaseCreateMutationInput = {
  accountNumber: Scalars['String']
  action: ServiceNowContactCaseCreateActionEnum
  contactId?: InputMaybe<Scalars['String']>
  emailAddress?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
  middleName?: InputMaybe<Scalars['String']>
  mobilePhone?: InputMaybe<Scalars['String']>
  supportPortalAccess?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<ServiceNowContactCaseEnum>
}

export enum ServiceNowContactCaseEnum {
  Commercial = 'COMMERCIAL',
  Invoicing = 'INVOICING',
  Regular = 'REGULAR',
  Security = 'SECURITY',
  Technical = 'TECHNICAL',
}

export type ServiceNowIncident = {
  __typename?: 'ServiceNowIncident'
  category?: Maybe<ServiceNowIncidentCategoryEnum>
  comments?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  priority?: Maybe<ServiceNowIncidentPriorityEnum>
  severity?: Maybe<ServiceNowIncidentSeverityEnum>
  shortDescription?: Maybe<Scalars['String']>
  state?: Maybe<ServiceNowIncidentStateEnum>
  sysCreatedBy?: Maybe<Scalars['String']>
  sysCreatedOn?: Maybe<Scalars['AWSDateTime']>
  sysId?: Maybe<Scalars['String']>
  sysUpdatedBy?: Maybe<Scalars['String']>
  sysUpdatedOn?: Maybe<Scalars['AWSDateTime']>
  workNotes?: Maybe<Scalars['String']>
}

export enum ServiceNowIncidentCategoryEnum {
  Database = 'DATABASE',
  Hardware = 'HARDWARE',
  Incident = 'INCIDENT',
  InquiryOrHelp = 'INQUIRY_OR_HELP',
  Network = 'NETWORK',
  NotSet = 'NOT_SET',
  SecurityIncident = 'SECURITY_INCIDENT',
  ServiceRequest = 'SERVICE_REQUEST',
  Software = 'SOFTWARE',
}

export type ServiceNowIncidentCategoryFilter = {
  operator?: InputMaybe<GenericEnumOperatorEnum>
  value: Array<ServiceNowIncidentCategoryEnum>
}

export enum ServiceNowIncidentCloseCodeEnum {
  AwaitingFixReview = 'AWAITING_FIX_REVIEW',
  ClosedOrResolvedByCaller = 'CLOSED_OR_RESOLVED_BY_CALLER',
  ClosedOrResolvedByCustomer = 'CLOSED_OR_RESOLVED_BY_CUSTOMER',
  InvalidRequest = 'INVALID_REQUEST',
  NotSet = 'NOT_SET',
  NotSolvedNotReproducible = 'NOT_SOLVED_NOT_REPRODUCIBLE',
  NotSolvedTooCostly = 'NOT_SOLVED_TOO_COSTLY',
  SolvedMaintenance = 'SOLVED_MAINTENANCE',
  SolvedPermanently = 'SOLVED_PERMANENTLY',
  SolvedRemotelyPermanently = 'SOLVED_REMOTELY_PERMANENTLY',
  SolvedRemotelyWorkAround = 'SOLVED_REMOTELY_WORK_AROUND',
  SolvedWorkAround = 'SOLVED_WORK_AROUND',
  Unresolved = 'UNRESOLVED',
}

export enum ServiceNowIncidentContactEnum {
  Chat = 'CHAT',
  Email = 'EMAIL',
  Monitoring = 'MONITORING',
  NotSet = 'NOT_SET',
  Phone = 'PHONE',
  SelfService = 'SELF_SERVICE',
}

export type ServiceNowIncidentFilter = {
  category?: InputMaybe<ServiceNowIncidentCategoryFilter>
  state?: InputMaybe<ServiceNowIncidentStateFilter>
  sysCreatedOn?: InputMaybe<GenericDateFilter>
}

export enum ServiceNowIncidentPriorityEnum {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE',
  NotSet = 'NOT_SET',
  Planning = 'PLANNING',
}

export enum ServiceNowIncidentSeverityEnum {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE',
  NotSet = 'NOT_SET',
}

export type ServiceNowIncidentSorting = {
  sysCreatedOn?: InputMaybe<GenericSortOrderEnum>
}

export enum ServiceNowIncidentStateEnum {
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  NotSet = 'NOT_SET',
  OnHold = 'ON_HOLD',
  Resolved = 'RESOLVED',
}

export type ServiceNowIncidentStateFilter = {
  operator?: InputMaybe<GenericEnumOperatorEnum>
  value: Array<ServiceNowIncidentStateEnum>
}

export enum ServiceNowIncidentSubcategoryEnum {
  Antivirus = 'ANTIVIRUS',
  Availability = 'AVAILABILITY',
  Claims = 'CLAIMS',
  CompromisedAsset = 'COMPROMISED_ASSET',
  Connectivity = 'CONNECTIVITY',
  Cpu = 'CPU',
  Db_2 = 'DB_2',
  DdoSAttack = 'DDO_S_ATTACK',
  Dhcp = 'DHCP',
  Disk = 'DISK',
  Dns = 'DNS',
  Email = 'EMAIL',
  FeatureAccess = 'FEATURE_ACCESS',
  Forms = 'FORMS',
  Interface = 'INTERFACE',
  InternalApplication = 'INTERNAL_APPLICATION',
  IpAddress = 'IP_ADDRESS',
  Keyboard = 'KEYBOARD',
  Maintenance = 'MAINTENANCE',
  Memory = 'MEMORY',
  Monitor = 'MONITOR',
  Mouse = 'MOUSE',
  MsSqlServer = 'MS_SQL_SERVER',
  Notifications = 'NOTIFICATIONS',
  NotSet = 'NOT_SET',
  OperatingSystem = 'OPERATING_SYSTEM',
  Oracle = 'ORACLE',
  Other = 'OTHER',
  Others = 'OTHERS',
  Performance = 'PERFORMANCE',
  PersonalDataBreach = 'PERSONAL_DATA_BREACH',
  Portal = 'PORTAL',
  PossibleThreat = 'POSSIBLE_THREAT',
  QuestionForCompliance = 'QUESTION_FOR_COMPLIANCE',
  QuestionForFinance = 'QUESTION_FOR_FINANCE',
  QuestionForSales = 'QUESTION_FOR_SALES',
  QuestionOther = 'QUESTION_OTHER',
  StandardOrder = 'STANDARD_ORDER',
  Vpn = 'VPN',
  VulnerabilityOr_0Day = 'VULNERABILITY_OR_0_DAY',
  Wireless = 'WIRELESS',
}

export type ServiceNowIncidentsResult = {
  __typename?: 'ServiceNowIncidentsResult'
  metadata: Metadata
  result?: Maybe<Array<Maybe<ServiceNowIncident>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type ServiceNowProblem = {
  __typename?: 'ServiceNowProblem'
  comments?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  priority?: Maybe<ServiceNowProblemPriorityEnum>
  shortDescription?: Maybe<Scalars['String']>
  state?: Maybe<ServiceNowProblemStateEnum>
  sysCreatedBy?: Maybe<Scalars['String']>
  sysCreatedOn?: Maybe<Scalars['AWSDateTime']>
  sysId?: Maybe<Scalars['String']>
  sysUpdatedBy?: Maybe<Scalars['String']>
  sysUpdatedOn?: Maybe<Scalars['AWSDateTime']>
  workNotes?: Maybe<Scalars['String']>
}

export enum ServiceNowProblemCategoryEnum {
  Database = 'DATABASE',
  Hardware = 'HARDWARE',
  ImprovingQualityReliability = 'IMPROVING_QUALITY_RELIABILITY',
  IncreaseComplianceSecurity = 'INCREASE_COMPLIANCE_SECURITY',
  MitigatingPotentialIncidents = 'MITIGATING_POTENTIAL_INCIDENTS',
  Network = 'NETWORK',
  NotSet = 'NOT_SET',
  PermanentSolutions = 'PERMANENT_SOLUTIONS',
  Software = 'SOFTWARE',
}

export type ServiceNowProblemFilter = {
  state?: InputMaybe<ServiceNowProblemStateFilter>
  sysCreatedOn?: InputMaybe<GenericDateFilter>
}

export enum ServiceNowProblemPriorityEnum {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE',
  NotSet = 'NOT_SET',
  Planning = 'PLANNING',
}

export enum ServiceNowProblemResolutionCodeEnum {
  Canceled = 'CANCELED',
  Duplicate = 'DUPLICATE',
  FixApplied = 'FIX_APPLIED',
  NotSet = 'NOT_SET',
  RiskAccepted = 'RISK_ACCEPTED',
}

export type ServiceNowProblemSorting = {
  sysCreatedOn?: InputMaybe<GenericSortOrderEnum>
}

export enum ServiceNowProblemStateEnum {
  Assess = 'ASSESS',
  Closed = 'CLOSED',
  FixInProgress = 'FIX_IN_PROGRESS',
  New = 'NEW',
  NotSet = 'NOT_SET',
  Resolved = 'RESOLVED',
  RootCauseAnalysis = 'ROOT_CAUSE_ANALYSIS',
}

export type ServiceNowProblemStateFilter = {
  operator?: InputMaybe<GenericEnumOperatorEnum>
  value: Array<ServiceNowIncidentCategoryEnum>
}

export type ServiceNowProblemsResult = {
  __typename?: 'ServiceNowProblemsResult'
  metadata: Metadata
  result?: Maybe<Array<Maybe<ServiceNowProblem>>>
  totalCount?: Maybe<Scalars['Int']>
}

export enum TemplateEnum {
  ConfigurationItems = 'CONFIGURATION_ITEMS',
  Contacts = 'CONTACTS',
}

export type UserResult = {
  __typename?: 'UserResult'
  accounts?: Maybe<Dynamics365CeAccountsResult>
}

export type ZabbixChart = {
  __typename?: 'ZabbixChart'
  items?: Maybe<Array<Maybe<ZabbixChartItems>>>
  name?: Maybe<Scalars['String']>
}

export type ZabbixChartDataFilter = {
  date: GenericDateTimeFilter
  itemId: GenericIntFilter
}

export type ZabbixChartItems = {
  __typename?: 'ZabbixChartItems'
  datetime?: Maybe<Scalars['AWSDateTime']>
  itemId?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Float']>
}

export type ZabbixChartMenu = {
  __typename?: 'ZabbixChartMenu'
  items?: Maybe<Array<Maybe<ZabbixChartMenuItem>>>
  name?: Maybe<Scalars['String']>
}

export type ZabbixChartMenuFilter = {
  host?: InputMaybe<GenericStringFilter>
  tags?: InputMaybe<Array<ZabbixTagFilter>>
}

export type ZabbixChartMenuItem = {
  __typename?: 'ZabbixChartMenuItem'
  delay?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  history?: Maybe<Scalars['String']>
  itemId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  units?: Maybe<Scalars['String']>
}

export type ZabbixChartMenusResult = {
  __typename?: 'ZabbixChartMenusResult'
  metadata?: Maybe<Metadata>
  result?: Maybe<Array<Maybe<ZabbixChartMenu>>>
}

export type ZabbixChartsResult = {
  __typename?: 'ZabbixChartsResult'
  metadata?: Maybe<Metadata>
  result?: Maybe<Array<Maybe<ZabbixChart>>>
}

export type ZabbixResult = {
  __typename?: 'ZabbixResult'
  chartData?: Maybe<ZabbixChartsResult>
  chartMenu?: Maybe<ZabbixChartMenusResult>
}

export type ZabbixResultChartDataArgs = {
  filters?: InputMaybe<ZabbixChartDataFilter>
}

export type ZabbixResultChartMenuArgs = {
  filters?: InputMaybe<ZabbixChartMenuFilter>
}

export type ZabbixTagFilter = {
  name: Scalars['String']
  operator?: InputMaybe<GenericStringFilter>
  value: Array<Scalars['String']>
}

export type ServiceNowAccountUpdateMutationVariables = Exact<{
  input: ServiceNowAccountUpdateMutation
}>

export type ServiceNowAccountUpdateMutation = {
  __typename?: 'Mutation'
  serviceNowAccountUpdate: {
    __typename?: 'ServiceNowAccountResult'
    metadata: {
      __typename?: 'Metadata'
      conversationId: string
      message: string
      statusCode: number
      statusText: string
    }
    result?: { __typename?: 'ServiceNowAccount'; sysId?: string | null } | null
  }
}

export type ServiceNowCaseCreateMutationVariables = Exact<{
  input: ServiceNowCaseCreateMutation
}>

export type ServiceNowCaseCreateMutation = {
  __typename?: 'Mutation'
  serviceNowCaseCreate: {
    __typename?: 'ServiceNowCaseResult'
    metadata: {
      __typename?: 'Metadata'
      conversationId: string
      message: string
      statusCode: number
      statusText: string
    }
    result?: { __typename?: 'ServiceNowCase'; sysId?: string | null } | null
  }
}

export type ServiceNowContactCaseCreateMutationVariables = Exact<{
  input: ServiceNowContactCaseCreateMutation
}>

export type ServiceNowContactCaseCreateMutation = {
  __typename?: 'Mutation'
  serviceNowContactCaseCreate: {
    __typename?: 'ServiceNowCaseResult'
    metadata: {
      __typename?: 'Metadata'
      conversationId: string
      message: string
      statusCode: number
      statusText: string
    }
    result?: { __typename?: 'ServiceNowCase'; sysId?: string | null } | null
  }
}

export type ReportFileGenerateMutationVariables = Exact<{
  input: ReportFileGenerateMutation
}>

export type ReportFileGenerateMutation = {
  __typename?: 'Mutation'
  reportFileGenerate: {
    __typename?: 'ReportFileGenerateResult'
    result?: {
      __typename?: 'PreSignedUrlResult'
      preSignedUrl?: string | null
    } | null
    metadata?: {
      __typename?: 'Metadata'
      conversationId: string
      statusCode: number
      statusText: string
      message: string
    } | null
  }
}

export type ListAccountWithOwnerAndManagerQueryVariables = Exact<{
  [key: string]: never
}>

export type ListAccountWithOwnerAndManagerQuery = {
  __typename?: 'Query'
  customers?: {
    __typename?: 'CustomersResult'
    accounts?: {
      __typename?: 'Dynamics365CEAccountsResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'Dynamics365CEAccount'
        accountId?: string | null
        accountNumber?: string | null
        accountName?: string | null
        emailAddresses?: Array<any | null> | null
        vatNumber?: string | null
        organizationalUnit?: string | null
        telephone?: string | null
        addresses?: {
          __typename?: 'Dynamics365CECustomerAddressesResult'
          result?: Array<{
            __typename?: 'Dynamics365CECustomerAddress'
            city?: string | null
            country?: string | null
            postalCode?: string | null
            lines?: Array<string | null> | null
          } | null> | null
          metadata?: {
            __typename?: 'Metadata'
            conversationId: string
            statusCode: number
            statusText: string
            message: string
          } | null
        } | null
        owner?: {
          __typename?: 'Dynamics365CESystemUserResult'
          result?: {
            __typename?: 'Dynamics365CESystemUser'
            emailAddress?: string | null
            firstName?: string | null
            lastName?: string | null
            mobilePhone?: string | null
          } | null
        } | null
        serviceManager?: {
          __typename?: 'Dynamics365CESystemUserResult'
          result?: {
            __typename?: 'Dynamics365CESystemUser'
            emailAddress?: string | null
            firstName?: string | null
            lastName?: string | null
            mobilePhone?: string | null
          } | null
        } | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListAccountWithServiceNowAccountQueryVariables = Exact<{
  filters: Dynamics365CeAccountFilter
}>

export type ListAccountWithServiceNowAccountQuery = {
  __typename?: 'Query'
  customers?: {
    __typename?: 'CustomersResult'
    accounts?: {
      __typename?: 'Dynamics365CEAccountsResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'Dynamics365CEAccount'
        accountNumber?: string | null
        serviceNowAccount?: {
          __typename?: 'ServiceNowAccountResult'
          result?: {
            __typename?: 'ServiceNowAccount'
            sysId?: string | null
            supportInformation?: string | null
          } | null
        } | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListUserAccountQueryVariables = Exact<{ [key: string]: never }>

export type ListUserAccountQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'UserResult'
    accounts?: {
      __typename?: 'Dynamics365CEAccountsResult'
      result?: Array<{
        __typename?: 'Dynamics365CEAccount'
        accountNumber?: string | null
        accountName?: string | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListCaseQueryVariables = Exact<{
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  filters?: InputMaybe<ServiceNowCaseFilter>
  sorting?: InputMaybe<
    Array<InputMaybe<ServiceNowCaseSorting>> | InputMaybe<ServiceNowCaseSorting>
  >
}>

export type ListCaseQuery = {
  __typename?: 'Query'
  operations?: {
    __typename?: 'OperationsResult'
    cases?: {
      __typename?: 'ServiceNowCasesResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'ServiceNowCase'
        comments?: string | null
        number?: string | null
        description?: string | null
        priority?: ServiceNowCasePriorityEnum | null
        state?: ServiceNowCaseStateEnum | null
        shortDescription?: string | null
        sysCreatedBy?: string | null
        sysCreatedOn?: any | null
        sysUpdatedBy?: string | null
        sysUpdatedOn?: any | null
        workNotes?: string | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        message: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListCaseByCiQueryVariables = Exact<{
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  filters?: InputMaybe<ServiceNowConfigurationItemFilter>
  sorting?: InputMaybe<
    Array<InputMaybe<ServiceNowCaseSorting>> | InputMaybe<ServiceNowCaseSorting>
  >
}>

export type ListCaseByCiQuery = {
  __typename?: 'Query'
  operations?: {
    __typename?: 'OperationsResult'
    configurationItems?: {
      __typename?: 'ServiceNowConfigurationItemsResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'ServiceNowConfigurationItem'
        name?: string | null
        cases?: {
          __typename?: 'ServiceNowCasesResult'
          totalCount?: number | null
          result?: Array<{
            __typename?: 'ServiceNowCase'
            comments?: string | null
            number?: string | null
            description?: string | null
            state?: ServiceNowCaseStateEnum | null
            shortDescription?: string | null
            sysCreatedBy?: string | null
            sysCreatedOn?: any | null
            workNotes?: string | null
          } | null> | null
        } | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        message: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListChangesQueryVariables = Exact<{
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  filters?: InputMaybe<ServiceNowChangeFilter>
  sorting?: InputMaybe<
    | Array<InputMaybe<ServiceNowChangeSorting>>
    | InputMaybe<ServiceNowChangeSorting>
  >
}>

export type ListChangesQuery = {
  __typename?: 'Query'
  operations?: {
    __typename?: 'OperationsResult'
    changes?: {
      __typename?: 'ServiceNowChangesResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'ServiceNowChange'
        number?: string | null
        description?: string | null
        impact?: ServiceNowChangeImpactEnum | null
        priority?: ServiceNowChangePriorityEnum | null
        state?: ServiceNowChangeStateEnum | null
        shortDescription?: string | null
        sysCreatedBy?: string | null
        sysCreatedOn?: any | null
        sysId?: string | null
        sysUpdatedBy?: string | null
        sysUpdatedOn?: any | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        message: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListCiQueryVariables = Exact<{
  filters?: InputMaybe<ServiceNowConfigurationItemFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
}>

export type ListCiQuery = {
  __typename?: 'Query'
  operations?: {
    __typename?: 'OperationsResult'
    configurationItems?: {
      __typename?: 'ServiceNowConfigurationItemsResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'ServiceNowConfigurationItem'
        cpuCount?: number | null
        hostName?: string | null
        installStatus?: ServiceNowConfigurationItemInstallStatusEnum | null
        ipAddress?: string | null
        name?: string | null
        os?: string | null
        osVersion?: string | null
        ram?: number | null
        shortDescription?: string | null
        sysClassName?: string | null
        sysId?: string | null
        logicalTag?: string | null
        patchDaySchedule?: string | null
        patchMonthSchedule?: string | null
        patchTime?: string | null
        patchWeekSchedule?: string | null
        patchingDisabledReason?: string | null
        patchingEnabled?: boolean | null
        supportLevel?: string | null
        vmTechnology?: string | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        message: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListCiCsvExportQueryVariables = Exact<{
  filters?: InputMaybe<ServiceNowConfigurationItemFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
}>

export type ListCiCsvExportQuery = {
  __typename?: 'Query'
  operations?: {
    __typename?: 'OperationsResult'
    configurationItems?: {
      __typename?: 'ServiceNowConfigurationItemsResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'ServiceNowConfigurationItem'
        hostName?: string | null
        installStatus?: ServiceNowConfigurationItemInstallStatusEnum | null
        name?: string | null
        os?: string | null
        osVersion?: string | null
        shortDescription?: string | null
        sysClassName?: string | null
        sysId?: string | null
        supportLevel?: string | null
        vmTechnology?: string | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        message: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListContactsQueryVariables = Exact<{
  filters?: InputMaybe<Dynamics365CeContactFilter>
  pagination?: InputMaybe<GenericPaginationLimitOffset>
}>

export type ListContactsQuery = {
  __typename?: 'Query'
  customers?: {
    __typename?: 'CustomersResult'
    contacts?: {
      __typename?: 'Dynamics365CEContactsResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'Dynamics365CEContact'
        contactType?: string | null
        emailAddress?: string | null
        firstName?: string | null
        fullName?: string | null
        lastName?: string | null
        mobilePhone?: string | null
        supportPortalAccess?: boolean | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        message: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListIncidentsQueryVariables = Exact<{
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  filters?: InputMaybe<ServiceNowIncidentFilter>
  sorting?: InputMaybe<
    | Array<InputMaybe<ServiceNowIncidentSorting>>
    | InputMaybe<ServiceNowIncidentSorting>
  >
}>

export type ListIncidentsQuery = {
  __typename?: 'Query'
  operations?: {
    __typename?: 'OperationsResult'
    incidents?: {
      __typename?: 'ServiceNowIncidentsResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'ServiceNowIncident'
        number?: string | null
        description?: string | null
        priority?: ServiceNowIncidentPriorityEnum | null
        state?: ServiceNowIncidentStateEnum | null
        severity?: ServiceNowIncidentSeverityEnum | null
        shortDescription?: string | null
        sysCreatedBy?: string | null
        sysCreatedOn?: any | null
        sysId?: string | null
        sysUpdatedBy?: string | null
        sysUpdatedOn?: any | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        message: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type GetPowerBiReportsQueryVariables = Exact<{
  filters: PowerBiFilterInput
}>

export type GetPowerBiReportsQuery = {
  __typename?: 'Query'
  insights?: {
    __typename?: 'InsightsResult'
    powerBI?: {
      __typename?: 'PowerBIResult'
      result?: {
        __typename?: 'PowerBIEmbedToken'
        embedToken?: string | null
        embedUrl?: string | null
        reportId?: string | null
      } | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        message: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ListProblemsQueryVariables = Exact<{
  pagination?: InputMaybe<GenericPaginationLimitOffset>
  filters?: InputMaybe<ServiceNowProblemFilter>
  sorting?: InputMaybe<
    | Array<InputMaybe<ServiceNowProblemSorting>>
    | InputMaybe<ServiceNowProblemSorting>
  >
}>

export type ListProblemsQuery = {
  __typename?: 'Query'
  operations?: {
    __typename?: 'OperationsResult'
    problems?: {
      __typename?: 'ServiceNowProblemsResult'
      totalCount?: number | null
      result?: Array<{
        __typename?: 'ServiceNowProblem'
        number?: string | null
        description?: string | null
        priority?: ServiceNowProblemPriorityEnum | null
        state?: ServiceNowProblemStateEnum | null
        shortDescription?: string | null
        sysCreatedBy?: string | null
        sysCreatedOn?: any | null
        sysId?: string | null
        sysUpdatedBy?: string | null
        sysUpdatedOn?: any | null
      } | null> | null
      metadata: {
        __typename?: 'Metadata'
        conversationId: string
        message: string
        statusCode: number
        statusText: string
      }
    } | null
  } | null
}

export type ChartMenuQueryVariables = Exact<{
  filters?: InputMaybe<ZabbixChartMenuFilter>
}>

export type ChartMenuQuery = {
  __typename?: 'Query'
  insights?: {
    __typename?: 'InsightsResult'
    zabbix?: {
      __typename?: 'ZabbixResult'
      chartMenu?: {
        __typename?: 'ZabbixChartMenusResult'
        result?: Array<{
          __typename?: 'ZabbixChartMenu'
          name?: string | null
          items?: Array<{
            __typename?: 'ZabbixChartMenuItem'
            delay?: string | null
            description?: string | null
            history?: string | null
            itemId?: string | null
            name?: string | null
            units?: string | null
          } | null> | null
        } | null> | null
        metadata?: {
          __typename?: 'Metadata'
          conversationId: string
          message: string
          statusCode: number
          statusText: string
        } | null
      } | null
    } | null
  } | null
}

export type ChartDataQueryVariables = Exact<{
  filters?: InputMaybe<ZabbixChartDataFilter>
}>

export type ChartDataQuery = {
  __typename?: 'Query'
  insights?: {
    __typename?: 'InsightsResult'
    zabbix?: {
      __typename?: 'ZabbixResult'
      chartData?: {
        __typename?: 'ZabbixChartsResult'
        result?: Array<{
          __typename?: 'ZabbixChart'
          name?: string | null
          items?: Array<{
            __typename?: 'ZabbixChartItems'
            value?: number | null
            datetime?: any | null
            itemId?: string | null
          } | null> | null
        } | null> | null
        metadata?: {
          __typename?: 'Metadata'
          conversationId: string
          message: string
          statusCode: number
          statusText: string
        } | null
      } | null
    } | null
  } | null
}

export const ServiceNowAccountUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'serviceNowAccountUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ServiceNowAccountUpdateMutation' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceNowAccountUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversationId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusText' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sysId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ServiceNowAccountUpdateMutation,
  ServiceNowAccountUpdateMutationVariables
>
export const ServiceNowCaseCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'serviceNowCaseCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ServiceNowCaseCreateMutation' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceNowCaseCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversationId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusText' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sysId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ServiceNowCaseCreateMutation,
  ServiceNowCaseCreateMutationVariables
>
export const ServiceNowContactCaseCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'serviceNowContactCaseCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ServiceNowContactCaseCreateMutation',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceNowContactCaseCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversationId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusText' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'sysId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ServiceNowContactCaseCreateMutation,
  ServiceNowContactCaseCreateMutationVariables
>
export const ReportFileGenerateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'reportFileGenerate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportFileGenerateMutation' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportFileGenerate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preSignedUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversationId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportFileGenerateMutation,
  ReportFileGenerateMutationVariables
>
export const ListAccountWithOwnerAndManagerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listAccountWithOwnerAndManager' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addresses' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'result' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'city' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'country',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'postalCode',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lines',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'conversationId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'statusCode',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'statusText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'message',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailAddresses' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vatNumber' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'organizationalUnit',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'result' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'emailAddress',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mobilePhone',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'serviceManager' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'result' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'emailAddress',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mobilePhone',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'telephone' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListAccountWithOwnerAndManagerQuery,
  ListAccountWithOwnerAndManagerQueryVariables
>
export const ListAccountWithServiceNowAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listAccountWithServiceNowAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Dynamics365CEAccountFilter' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accounts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountNumber' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'serviceNowAccount',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'result' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'sysId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'supportInformation',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListAccountWithServiceNowAccountQuery,
  ListAccountWithServiceNowAccountQueryVariables
>
export const ListUserAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listUserAccount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountNumber' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListUserAccountQuery,
  ListUserAccountQueryVariables
>
export const ListCaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GenericPaginationLimitOffset' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ServiceNowCaseFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sorting' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ServiceNowCaseSorting' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'operations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cases' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sorting' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sorting' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'comments' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priority' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysCreatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysCreatedOn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysUpdatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysUpdatedOn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'workNotes' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListCaseQuery, ListCaseQueryVariables>
export const ListCaseByCiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCaseByCI' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GenericPaginationLimitOffset' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ServiceNowConfigurationItemFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sorting' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ServiceNowCaseSorting' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'operations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configurationItems' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cases' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'pagination' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'pagination' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'sorting' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sorting' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'result' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'comments',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'number',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'state',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'shortDescription',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'sysCreatedBy',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'sysCreatedOn',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'workNotes',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalCount' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListCaseByCiQuery, ListCaseByCiQueryVariables>
export const ListChangesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListChanges' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GenericPaginationLimitOffset' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ServiceNowChangeFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sorting' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ServiceNowChangeSorting' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'operations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'changes' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sorting' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sorting' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'impact' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priority' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysCreatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysCreatedOn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysUpdatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysUpdatedOn' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListChangesQuery, ListChangesQueryVariables>
export const ListCiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCI' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ServiceNowConfigurationItemFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GenericPaginationLimitOffset' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'operations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configurationItems' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cpuCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hostName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'installStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ipAddress' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'os' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'osVersion' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ram' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysClassName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logicalTag' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'patchDaySchedule' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'patchMonthSchedule',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'patchTime' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'patchWeekSchedule',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'patchingDisabledReason',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'patchingEnabled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'supportLevel' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vmTechnology' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListCiQuery, ListCiQueryVariables>
export const ListCiCsvExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListCICsvExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ServiceNowConfigurationItemFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GenericPaginationLimitOffset' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'operations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configurationItems' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hostName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'installStatus' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'os' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'osVersion' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysClassName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'supportLevel' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vmTechnology' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListCiCsvExportQuery,
  ListCiCsvExportQueryVariables
>
export const ListContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListContacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dynamics365CEContactFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GenericPaginationLimitOffset' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contacts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contactType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailAddress' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobilePhone' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'supportPortalAccess',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListContactsQuery, ListContactsQueryVariables>
export const ListIncidentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListIncidents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GenericPaginationLimitOffset' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ServiceNowIncidentFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sorting' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ServiceNowIncidentSorting' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'operations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'incidents' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sorting' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sorting' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priority' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'severity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysCreatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysCreatedOn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysUpdatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysUpdatedOn' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListIncidentsQuery, ListIncidentsQueryVariables>
export const GetPowerBiReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPowerBiReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PowerBIFilterInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'powerBI' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'embedToken' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'embedUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPowerBiReportsQuery,
  GetPowerBiReportsQueryVariables
>
export const ListProblemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListProblems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GenericPaginationLimitOffset' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ServiceNowProblemFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sorting' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ServiceNowProblemSorting' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'operations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'problems' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sorting' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sorting' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'number' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'priority' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysCreatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysCreatedOn' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysUpdatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sysUpdatedOn' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conversationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListProblemsQuery, ListProblemsQueryVariables>
export const ChartMenuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ChartMenu' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ZabbixChartMenuFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'zabbix' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chartMenu' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'result' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'delay',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'history',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'itemId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'units',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'conversationId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusCode' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusText' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChartMenuQuery, ChartMenuQueryVariables>
export const ChartDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ChartData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ZabbixChartDataFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insights' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'zabbix' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chartData' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'result' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'datetime',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'itemId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metadata' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'conversationId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusCode' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statusText' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChartDataQuery, ChartDataQueryVariables>
