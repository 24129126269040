import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const homeRoutes: RouteRecordRaw = {
  name: 'Home',
  path: 'home',
  component: () => import('./HomeView.vue'),
  meta: {
    title: 'My Accenture | Home',
    requiresAuth: true,
    roles: AUTH_MODULE.HOME,
  },
}

export { homeRoutes }
