import { RouteRecordRaw } from 'vue-router'
import AuthLayout from '@/view/partials/AuthLayout.vue'

const authRoutes: RouteRecordRaw = {
  name: 'Auth',
  path: '/auth/',
  component: AuthLayout,
  children: [
    {
      name: 'Login',
      path: 'login',
      component: () => import('./login/LoginView.vue'),
      meta: {
        title: 'My Accenture | Login',
      },
    },
    {
      name: 'Callback',
      path: 'implicit/callback',
      component: () => import('./callback/CallbackView.vue'),
      meta: {
        title: 'My Accenture | Callback',
      },
    },
    {
      name: 'Logout',
      path: 'logout',
      component: () => import('./logout/LogoutView.vue'),
      meta: {
        title: 'My Accenture | Logout',
      },
    },
  ],
}

export { authRoutes }
