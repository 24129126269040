import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const serviceRequestsRoutes: RouteRecordRaw = {
  name: 'Reports Service Requests',
  path: 'service-requests',
  redirect: { name: 'Open Service Requests' },
  component: () => import('./ServiceRequestsLayout.vue'),
  children: [
    {
      name: 'Closed Service Requests',
      path: 'closed',
      component: () => import('./ClosedServiceRequests.vue'),
      meta: {
        title: 'My Accenture | Closed Service Requests',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Created Service Requests',
      path: 'created',
      component: () => import('./CreatedServiceRequests.vue'),
      meta: {
        title: 'My Accenture | Created Service Requests',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Open Service Requests',
      path: 'open',
      component: () => import('./OpenServiceRequests.vue'),
      meta: {
        title: 'My Accenture | Open Service Requests',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
  ],
}

export { serviceRequestsRoutes }
