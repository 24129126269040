import { defineStore } from 'pinia'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { computed, ref } from 'vue'
import { Auth } from '@aws-amplify/auth'
import { useNotificationStore } from '@/store/useNotificationStore'
import { useAccounts } from '@/store/useAccount'
import { useConfigurationItem } from '@/store/useConfigurationItem'
import { setUser } from '@sentry/vue'

export const useAuthentication = defineStore('authentication', () => {
  const { clearAccounts } = useAccounts()
  const { clearConfigurationItem } = useConfigurationItem()
  const notificationStore = useNotificationStore()
  const currentUser = ref<CognitoUser>()

  const accessToken = computed<string | undefined>(() =>
    currentUser.value?.getSignInUserSession()?.getAccessToken().getJwtToken()
  )
  const accessTokenPayload = computed(
    (): { [index: string]: any } | undefined =>
      currentUser.value
        ?.getSignInUserSession()
        ?.getAccessToken()
        .decodePayload()
  )
  const currentSession = computed<CognitoUserSession | null | undefined>(() =>
    currentUser.value?.getSignInUserSession()
  )
  const idToken = computed<string | undefined>(() =>
    currentUser.value?.getSignInUserSession()?.getIdToken().getJwtToken()
  )
  const idTokenPayload = computed(
    (): { [index: string]: unknown } | undefined =>
      currentUser.value?.getSignInUserSession()?.getIdToken().decodePayload()
  )
  const isAuthenticated = computed<boolean>(
    () => currentUser.value?.getSignInUserSession()?.isValid() || false
  )
  const currentUserGroups = computed<string[]>(
    () =>
      currentUser.value
        ?.getSignInUserSession()
        ?.getAccessToken()
        .decodePayload()['cognito:groups']
  )
  async function fetchUser(): Promise<void> {
    const account = useAccounts()

    currentUser.value = await Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => undefined)
    if (isAuthenticated.value) {
      account.setAllowedAccounts(currentUserGroups.value)
      setUser({ email: idTokenPayload.value?.email as string })
    }
  }

  async function refreshSession(): Promise<void> {
    let error = undefined
    if (currentUser.value) {
      const refreshToken = currentUser.value
        ?.getSignInUserSession()
        ?.getRefreshToken()
      if (refreshToken) {
        currentUser.value.refreshSession(refreshToken, (): void => {
          error = 'Refreshing user failed, please sign in.'
        })
      } else {
        error = 'Refreshing user failed, please sign in.'
      }
    } else {
      error = 'No signed in user found, please sign in.'
    }
    if (error) {
      location.reload()
      notificationStore.showError(error)
    }
  }

  async function signIn(): Promise<boolean> {
    currentUser.value = await Auth.currentAuthenticatedUser()
    return !!currentUser.value
  }

  async function signOut(): Promise<void> {
    clearAccounts()
    clearConfigurationItem()
    currentUser.value = undefined
    await Auth.signOut({ global: true })
  }

  function isAllowed(roles: string[]) {
    const currentUserRoles = currentUserGroups?.value
    let isAllowed = false
    roles.forEach((role) => {
      if (currentUserRoles?.includes(role)) {
        isAllowed = true
      }
    })
    return isAllowed
  }

  return {
    accessToken,
    currentUser,
    fetchUser,
    isAuthenticated,
    refreshSession,
    signIn,
    signOut,
    accessTokenPayload,
    currentSession,
    idToken,
    idTokenPayload,
    currentUserGroups,
    isAllowed,
  }
})
