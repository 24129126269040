import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { useAuthentication } from '@/store/useAuthentication'
import { useNotificationStore } from '@/store/useNotificationStore'
import { useAccounts } from '@/store/useAccount'
import { findPropertyPath, jsonPathToValue } from '@/utils/json'
import { Metadata } from '@/gql'
import { useLoading } from '@/store/useLoading'
import { captureException } from '@sentry/vue'

interface OperationContext {
  headers: Record<string, string>
}

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_MY_GRAPHQL_URL,
})

const metadataLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const metadataPath = findPropertyPath(
      data.data as Record<string, any>,
      'metadata'
    )
    if (metadataPath) {
      const metadata: Metadata = jsonPathToValue(
        metadataPath,
        data.data as Record<string, any>
      ) as Metadata
      if (metadata.statusCode !== 200) {
        const notification = useNotificationStore()
        notification.showError(`Something went wrong while executing the call.`)
        captureException(
          `${metadata.conversationId} - ${metadata.statusCode} - ${metadata.statusText} - ${metadata.message}`
        )
      }
    }
    return data
  })
})

const errorLink = onError(({ graphQLErrors }) => {
  const notification = useNotificationStore()
  const loading = useLoading()
  loading.loadingCount--
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) =>
      captureException(`[GraphQL error]: Message: '${message}'`)
    )

    graphQLErrors.forEach(({ message }) => {
      notification.showError(message)
    })
  }
})

const authLink = new ApolloLink((operation, forward) => {
  const authentication = useAuthentication()
  const account = useAccounts()
  operation.setContext(({ headers }: OperationContext) => {
    return {
      headers: {
        Authorization: authentication.accessToken,
        'x-account-number': account.currentAccount,
        ...headers,
      },
    }
  })
  return forward(operation)
})

const cache = new InMemoryCache({ addTypename: false })

export const apolloClient = new ApolloClient({
  cache,
  link: from([errorLink, authLink, metadataLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
