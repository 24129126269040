<template>
  <li :class="classList" @click="dismissManually">
    <!-- eslint-disable vue/no-v-html -->
    <div>
      <span
        class="absolute inset-y-0 left-0 flex items-center px-4"
        v-html="icon"
      >
      </span>
      <span class="pl-7 pr-4" v-html="props.notification.message"></span>
    </div>
    <!--eslint-enable-->
  </li>
</template>
<script setup lang="ts">
  import { computed, onMounted } from 'vue'
  import { Notification } from './types.d'
  import { useNotificationStore } from '@/store/useNotificationStore'
  import { useAccentureStyle } from '@/composable/useAccentureStyle'
  import delay from '@/utils/delay'

  const icons: { [index: string]: string } = {
    danger: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
</svg>`,
    success: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
</svg>`,
  }

  const props = defineProps<{ notification: Notification }>()
  const notificationStore = useNotificationStore()
  const style = useAccentureStyle()

  const classList = computed(() => {
    let classes: Array<string>
    classes = [
      'relative mb-2 py-4 pl-4 pr-10 leading-normal rounded',
      style.colors[props.notification?.color ?? 'success'],
      style.outlineBackgroundColors[props.notification?.color ?? 'success'],
      props.notification?.dismiss?.manually ? 'cursor-pointer' : '',
    ]

    return classes
  })

  const icon = computed(() => {
    return icons[props.notification?.color ?? 'success']
  })

  const dismissManually = () => {
    if (!props.notification?.dismiss?.manually) return
    notificationStore.unsetNotification(props.notification.id)
  }
  const dismissAutomatically = async () => {
    if (!props.notification?.dismiss?.automatically) return
    await delay(props.notification.duration || 0)
    notificationStore.unsetNotification(props.notification.id)
  }
  onMounted(() => {
    dismissAutomatically()
  })
</script>
