import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const costInsightsRoutes: RouteRecordRaw = {
  name: 'Cost Insights',
  path: 'insights/cost',
  component: () => import('./CostInsights.vue'),
  meta: {
    title: 'My Accenture | Cost Insights',
    requiresAuth: true,
    roles: AUTH_MODULE.REPORTS,
  },
}

export { costInsightsRoutes }
